import { createContext, useMemo, useState } from "react";
import {PreviewData} from "../interfaces";

interface packagingBriefPreviewContext {
  briefPreviewData: {data: PreviewData, pageTitle: string}
    | null;
  setBriefPreviewData: Function;
}

export const PackagingBriefPreviewContext = createContext<packagingBriefPreviewContext>({
  briefPreviewData: null,
  setBriefPreviewData: () => {},
});

export function PackagingBriefPreviewProvider({ children }: any) {
  const [briefPreviewData, setBriefPreviewData] = useState(null);
  return (
    <PackagingBriefPreviewContext.Provider
      value={useMemo(
        () => ({ briefPreviewData, setBriefPreviewData }),
        [briefPreviewData, setBriefPreviewData]
      )}
    >
      {children}
    </PackagingBriefPreviewContext.Provider>
  );
}
