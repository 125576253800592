import { ReactNode, useContext, useEffect, useState } from "react";
import { TabsProps as VdsTabsProps, Tabs as VdsTabs } from "@vds/tabs";
import { FlexBox } from "components";
import { StyledTab, TabsWrapper } from "./Tabs.module";
import { SavedSearchContext } from "contexts/savedSearchContext";

export interface TabProps {
  label: string;
  content: ReactNode;
}
export interface TabsProps {
  tabs: Array<TabProps>;
  small?: boolean;
  disabled?: boolean;
  noBorder?: boolean;
  onSelectTab?: (index: number) => void;
  orientation?: "horizontal" | "vertical";
  category?: boolean;
  width?: string | number;
  minWidth?: string;
}

const Tabs = ({
  selectedIndex: initialIndex,
  tabs,
  onTabChange,
  disabled = false,
  small,
  noBorder,
  onSelectTab,
  orientation,
  category,
  width,
  minWidth
}: TabsProps & VdsTabsProps) => {
  const { savedSearch } = useContext<any>(SavedSearchContext);

  const [selectedIndex, _setSelectedIndex] = useState<number | undefined>(
      initialIndex
    ),
    _onTabChange = (_: any, index: number) => {
      _setSelectedIndex(index);
      onTabChange && onTabChange(index);
      if (onSelectTab) {
        onSelectTab(index);
      }
    };

  const searchTabs = [
    "Assets",
    "Guidelines",
    "Templates",
    "Training",
    "Retail",
  ];
  useEffect(() => {
    if (savedSearch && category) {
      const index = searchTabs.findIndex(
        (tab: string) => tab === savedSearch?.category
      );
      if (index !== -1) {
        _setSelectedIndex(index);
      }
      _setSelectedIndex(0);
    }
  }, [savedSearch]);

  return (
    <TabsWrapper noBorder={noBorder} small={small}>
      <VdsTabs
        width={width}
        minWidth={minWidth}
        selectedIndex={selectedIndex}
        onTabChange={_onTabChange}
        overflow="scroll"
        orientation={orientation}
      >
        {tabs.map((tab: TabProps, i: number) => (
          <StyledTab
            key={i}
            className={selectedIndex === i ? "active" : ""}
            label={tab.label}
            // @ts-ignore
            disabled={disabled}
          >
            <FlexBox pd="1rem 0 0 0">{tab.content}</FlexBox>
          </StyledTab>
        ))}
      </VdsTabs>
    </TabsWrapper>
  );
};

export default Tabs;
