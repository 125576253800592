import styled from "styled-components";
import { zIndex } from "theme/variables";

interface WrapperInterface {
  open?: boolean;
}
export const StyledWrapper = styled.div<WrapperInterface>`
  z-index: ${zIndex.notification};

  ${(props) => props.open && `margin-top: 1.5rem;`};
  
  &.inline {
    position:relative;
    right: 0;

    > div > div {
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }

  &.absolute {
    position: fixed;
    top: 5vh;
    right: 0;

    > div > div {
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }
`;
