import { createTheme } from "@mui/material";
import { zIndex } from "./variables";
import type {} from "@mui/x-date-pickers-pro/themeAugmentation";

const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 992,
      xl: 1024,
    },
  },
  palette: {
    // this is for the autocomplete focus color
    secondary: {
      main: "#000000",
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:before": {
            backgroundColor: "#d8dada",
          },
          "&.first": {
            "&:before": {
              height: "0px"
            }
          },
          "&.retailNav": {
            "&.Mui-expanded": {
              margin: 0,
            },
          },
          "&.Mui-expanded": {
            "&:before": {
              opacity: 1,
            },
          },
          "&.Mui-disabled": {
            backgroundColor: "inherit",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.dark": {
            backgroundColor: "#000000",
            borderRadius: "0.375rem",
          },
          "&.retailNav": {
            minHeight: "unset",
            "&.Mui-expanded": {
              minHeight: "unset",
            },
            "&.active": {
              borderLeft: "4px solid #ee0000",
            },
          },
          padding: "1rem 1.5rem",
          borderBottom: "1px solid #d8dada",
          "&.Mui-expanded": {
            borderBottom: "none",
          },
          "&#hidden": {
            display: "none",
          },
        },
        content: {
          margin: 0,
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "1rem 1.5rem",
          borderBottom: "1px solid #f6f6f6",
          "&.retailNav": {
            padding: "1rem 0",
          },
          "&.maxHeight": {
            maxHeight: "12rem",
            overflowY: "auto",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
        },
        input: {
          padding: "0 !important",
        },
        popupIndicator: {
          "&:hover": {
            background: "transparent",
          },
        },
        // noOptions: {
        //   display: "none",
        // },
        popper: {
          zIndex: zIndex.modal,
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          gap: "1rem",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          zIndex: zIndex.pageLoader,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "&.baseline": {
            alignItems: "baseline",
          },
          "&.file-summary-grid": {
            marginLeft: "0",
            marginTop:"0",
            width: "100%",
            rowGap: "1rem",
          }
        },
      },
    },
    // MuiOutlinedInput: all for autocomplete
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: "0.75rem 2.75rem 0.75rem 0.75rem !important",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
          },
        },
        notchedOutline: {
          border: "solid 0.0625rem rgb(111, 113, 113)",
          "&.Mui-focused": {
            borderWidth: "1px",
          },
          // DISCUSS: not sure this works yet
          // "&.Mui-error": {
          //   backgroundColor: "rgb(255, 236, 224)",
          //   "&:hover": {
          //     border: "0.0625rem solid black",
          //   },
          // },
        },
        input: {
          height: "20px",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: zIndex.modal,
          "&[aria-hidden=true]": {
            "> div:nth-of-type(3)": {
              visibility: "hidden",
            },
          },
        },
        backdrop: {
          // custom modal backdrop
          backdropFilter: "blur(0.25rem)",
          backgroundColor: "rgba(0,0,0,.75)",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        // Name of the slot
        root: {
          width: "50px",
          height: "24px",
          padding: "0px",
        },
        switchBase: {
          padding: "1px",
          "&.Mui-checked": {
            transform: "translateX(25px) !important",
            "& + .MuiSwitch-track": {
              backgroundColor: "#000000",
            },
          },
        },
        thumb: {
          color: "white",
          width: "20px",
          height: "20px",
          margin: "1px",
        },
        track: {
          borderRadius: "20px",
          backgroundColor: "#d8dada",
          opacity: "1 !important",
          "&:after, &:before": {
            color: "white",
            fontSize: "11px",
            fontWeight: "bold",
            position: "absolute",
            top: "6px",
          },
          "&:after": {
            content: "'On'",
            fontFamily: `"Verizon-NHG-eDS", "Helvetica Neue", "helvetica", "arial"`,
            left: "8px",
          },
          "&:before": {
            content: "'Off'",
            fontFamily: `"Verizon-NHG-eDS", "Helvetica Neue", "helvetica", "arial"`,
            right: "7px",
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: "0",
          boxShadow: "none",
          // borderBottom: "1px solid #747676",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderBottom: "none",
        },
        body: {
          borderBottom: "none",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: "#FFFFFF",
          "&:before": {
            border: "1px solid #000000",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: "#000000",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.date-picker": {
            width: "100%",
            ".MuiFormHelperText-root": {
              marginLeft: "0",
            },
            ".MuiInputLabel-root": {
              transform: "translate(48px, 10px) scale(1)",
              webkitTransform: "translate(48px, 10px) scale(1)",
              fontFamily: `"Verizon-NHG-eDS", "Helvetica Neue", "helvetica", "arial"`,
              color: "rgb(111, 113, 113)",
            },
            ".MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.MuiInputBase-colorPrimary":
              {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000000",
                },
              },
            ".MuiInputLabel-shrink": {
              transform: "translate(14px, -10px) scale(0.75)",
              webkitTransform: "translate(14px, -10px) scale(0.75)",
              color: "#000000",
            },
            ".MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
              color: "#000000",
            },
            ".MuiOutlinedInput-root": {
              padding: "0.75rem !important",
              backgroundColor: "#ffffff",
              input: {
                padding: "0 0 0 0.75rem",
                fontFamily: `"Verizon-NHG-eDS", "Helvetica Neue", "helvetica", "arial"`,
              },
              ".MuiInputAdornment-positionStart": {
                marginLeft: "0",
                marginRight: "0.5rem",
                ".MuiButtonBase-root:hover": {
                  backgroundColor: "transparent",
                },
                ".MuiButtonBase-root.Mui-focusVisible": {
                  backgroundColor: "transparent",
                },
                ".MuiButtonBase-root": {
                  ".MuiTouchRipple-root": {
                    display: "none",
                  },
                },
              },
            },
          },
        },
      },
    },
    MuiPickersFadeTransitionGroup: {
      styleOverrides: {
        root: {
          fontFamily: `"Verizon-NHG-eDS", "Helvetica Neue", "helvetica", "arial"`,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily: `"Verizon-NHG-eDS", "Helvetica Neue", "helvetica", "arial"`,
        },
      },
    },
    MuiPickersSlideTransition: {
      styleOverrides: {
        root: {
          ".MuiDayCalendar-monthContainer": {
            ".MuiDayCalendar-weekContainer": {
              ".MuiButtonBase-root": {
                ".MuiTouchRipple-root": {
                  display: "none",
                },
              },
              ".MuiButtonBase-root.MuiPickersDay-root": {
                backgroundColor: "#ffffff",
                borderRadius: "0.25rem",
                fontFamily: `"Verizon-NHG-eDS", "Helvetica Neue", "helvetica", "arial"`,
              },
              ".MuiButtonBase-root.MuiPickersDay-root:hover": {
                backgroundColor: "#ffffff",
                borderRadius: "0.25rem",
                border: "1px solid #000000",
              },
              ".MuiButtonBase-root.MuiPickersDay-root.Mui-selected": {
                backgroundColor: "#000000",
                borderRadius: "0.25rem",
                color: "#ffffff",
              },
              // ".MuiPickersDay-today": {
              //   backgroundColor: "#000000",
              //   borderRadius: "0.25rem",
              // },
            },
          },
        },
      },
    },
    MuiYearCalendar: {
      styleOverrides: {
        root: {
          ".MuiPickersYear-root": {
            fontFamily: `"Verizon-NHG-eDS", "Helvetica Neue", "helvetica", "arial"`,
            ".MuiPickersYear-yearButton.Mui-selected": {
              borderRadius: "0.275rem",
              backgroundColor: "#000000",
            },
            ".MuiPickersYear-yearButton.Mui-selected:hover": {
              borderRadius: "0.275rem",
              backgroundColor: "#000000",
              border: "none",
            },
            ".MuiPickersYear-yearButton:hover": {
              borderRadius: "0.275rem",
              backgroundColor: "#ffffff",
              border: "1px solid #000000",
            },
          },
        },
      },
    },
    MuiMonthCalendar: {
      styleOverrides: {
        root: {
          "*": {
            fontFamily: "",
          },
          ".MuiPickersMonth-root": {
            ".MuiPickersMonth-monthButton.Mui-selected": {
              borderRadius: "0.275rem",
              backgroundColor: "#000000",
            },
            ".MuiPickersMonth-monthButton.Mui-selected:hover": {
              borderRadius: "0.275rem",
              backgroundColor: "#000000",
              border: "none",
            },
            ".MuiPickersMonth-monthButton:hover": {
              borderRadius: "0.275rem",
              backgroundColor: "#ffffff",
              border: "1px solid #000000",
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            backgroundColor: "rgb(255, 236, 224) !important",
            // "&:hover": {
            //   border: "0.0625rem solid black",
            // },
          },
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: "100005",
        },
      },
    },
  },
});

export default muiTheme;
