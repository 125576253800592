import {useEffect, useState} from "react";
import {TextButton, Button, FlexBox, Modal, Title, Loader, MultiSelect} from "components";
import {useLazyGetPackagingManagerGroupsQuery, useCreateProjectTeamMutation} from "services/api/api.slice";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";
import Input from "../../components/forms/Input/Input";
import {ProjectGroupProps} from "../../interfaces/PackagingManager";
import { v4 as uuid } from 'uuid';



export interface NewPackagingTeamModalProps {
  onClose: Function;
  onComplete: Function;
}

export interface NewTeamProps {
  id: string;
  name: string;
  groups: Array<ProjectGroupProps>;
}

const NewPackagingTeamModal = ({onClose, onComplete}: NewPackagingTeamModalProps) => {
  
  const blankTeam = {
    id: uuid(),
    name: "",
    groups: []
  }
  const dispatch = useDispatch();
  
  const [newTeam, _newTeam] = useState<NewTeamProps>(blankTeam);

  const handleClose = () => {
    onClose();
  };
  
  const handleTeamNameChange = (e: any) => {
    _newTeam((prevState) => {
      return {
        id: prevState.id,
        name: e.target.value,
        groups: [...prevState.groups.map((group) => group)]
      };
    });
  }
  
  const [groups, _groups] = useState<Array<ProjectGroupProps>>([]);
  const setGroups = (groupData: Array<ProjectGroupProps>) => {
    _groups(groupData);
  };
  const [callGetGroups, { data: initialGroups, isFetching: groupsFetching, isLoading: groupsLoading }] =
    useLazyGetPackagingManagerGroupsQuery()
  
  const getGroups = () => {
    callGetGroups()
    .unwrap()
    .catch((error: unknown) => {
      dispatch(
        setNotification({
          type: "error",
          message: "Could not fetch groups.",
        })
      );
      console.error(error);
    });
  };
  
  useEffect(() => {
    getGroups();
  }, []);
  
  useEffect(() => {
    if (initialGroups && initialGroups.length > 0) {
      setGroups(initialGroups);
    }
  }, [initialGroups]);
  
  useEffect(() => {
    if (initialGroups && initialGroups.length > 0) {
      let tempGroups: Array<ProjectGroupProps> = initialGroups.map((group) => group);
      if (newTeam.groups.length > 0) {
        if (tempGroups.length !== newTeam.groups.length) {
          
            newTeam.groups.forEach((selectedGroup) => {
              tempGroups.filter((initialGroup) => {
                if (selectedGroup.id !== initialGroup.id) {
                  return initialGroup;
                }
              })
            });
          _groups(tempGroups);
        }
      } else {
        _groups(initialGroups);
      }
    }

  }, [newTeam]);
  
  const updateTeamGroups = (updatedGroups: Array<ProjectGroupProps>) => {
    const newGroups = updatedGroups.map((group) => group);
    
    if (initialGroups && initialGroups.length > 0) {

        _newTeam((currentTeam) => {
          return {
            id: currentTeam.id,
            name: currentTeam.name,
            groups: [...newGroups]
          };
      });
    }
  };
  
  const removeTeamGroup = (selected: ProjectGroupProps) => () => {
    
    if (initialGroups && initialGroups.length > 0) {
      
      _newTeam((currentTeam) => {
        const newGroups = currentTeam.groups.filter((group) => group.id !== selected.id);
        return {
          id: currentTeam.id,
          name: currentTeam.name,
          groups: [...newGroups]
        };
      });
    }
  };
  
  
  
  return (
    <Modal testId="new-project-team-modal" open onClose={handleClose} disabled={groupsLoading}>
      
      <FlexBox minWidth="100%">
        <Title size="large" bold pd="0 0 2rem">
          Add new team
        </Title>
        
            <FlexBox pd="0 0 1.5rem">
              <Input
                name="newTeamName"
                value={newTeam.name}
                onChange={handleTeamNameChange}
                label="Team name"
                maxWidth="60ch"
              />
            </FlexBox>
  
        {(initialGroups && initialGroups.length > 0) && (
          <FlexBox pd="0 0 2rem 0" maxWidth="60ch">
            <MultiSelect
              title
              label="Groups"
              value={newTeam.groups}
              options={groups}
              onChange={(_, value: Array<ProjectGroupProps>, reason) => {
                if (reason === "selectOption") {
                  updateTeamGroups(value)
                }}
              }
              removeSelected={(selected: any) => removeTeamGroup(selected)}
              // TODO: add error handling
              orientation="vertical"
            />
          </FlexBox>
        )}
        
            
        <FlexBox row gap="1.5rem" align="center" justify="flex-start">
          <Button
            use="primary"
            data-testid="submit-button"
            onClick={() => onComplete(newTeam)}
            disabled={groupsLoading || !(newTeam.name.trim().length > 0) || newTeam.groups.length === 0}
          >
            Add team
          </Button>
          <TextButton data-testid="cancel-button" onClick={handleClose} disabled={groupsLoading}>
            Cancel
          </TextButton>
        </FlexBox>
      </FlexBox>
      
      {groupsLoading && (
        <Loader containerHeight="100%" active />
      )}
    </Modal>
  );
};

export default NewPackagingTeamModal;
