import { useState } from "react";
import {TextButton, Button, FlexBox, Modal, Title, Body, Loader} from "components";
import {useDeleteFileVersionMutation} from "services/api/api.slice";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";


export interface DeletePackagingManagerFileVersionModalProps {
  parentFileId: string;
  fileId: string;
  instanceId: string;
  onClose: Function;
  onComplete: Function;
  fileName: string;
  instanceType: "project" | "task" | "form";
}

const DeletePackagingManagerFileVersionModal = ({
                           parentFileId,
                           instanceId,
                           onClose,
                           onComplete,
                           fileName,
                           instanceType,
                           fileId,
                         }: DeletePackagingManagerFileVersionModalProps) => {
  
  const dispatch = useDispatch();
  
  const [deleteFileVersion, {data: fileDeletedData, isLoading: deleteLoading}] = useDeleteFileVersionMutation();
  const [isLoading, _isLoading] = useState<boolean>(false);
  const callDeleteFile = () => {
    _isLoading(true);
    deleteFileVersion({instanceId: instanceId, parentFileId: parentFileId, fileId: fileId, attachedToType: instanceType})
    .unwrap()
    .then(() => {
      dispatch(
        setNotification({
          type: "success",
          message: `File version successfully deleted`,
        })
      );
      onComplete();
    })
    .catch((error: any) => {
      dispatch(
        setNotification({
          type: "error",
          message: `Could not delete file version`,
        })
      );
      console.error(error);
    })
    .finally(() => {
      _isLoading(false);
    });
    
  };
  
  const handleClose = () => {
    onClose();
  };
  
  return (
    <Modal open={true} testId="delete-task-modal" onClose={handleClose} disabled={isLoading || deleteLoading}>
      <FlexBox maxWidth="100%">
        <Title size="large" bold pd="0 0 0.5rem">
          Delete file version:
        </Title>
        <Title size="small" bold pd="0 0 2rem">
          {fileName}
        </Title>
        {isLoading || deleteLoading ? (
          <FlexBox align="center" justify="center">
            <Loader active containerHeight="10rem" />
          </FlexBox>
        ) : (
          <>
            <Body size="large" pd="0 0 2rem">
              Are you sure you want to delete this file version? Deleted file versions are permanently removed and will no longer be accessible.
            </Body>
            <FlexBox row gap="1.5rem" align="center" justify="flex-start">
              <Button data-testid="submit-button" use="primary" onClick={callDeleteFile} disabled={isLoading || deleteLoading}>
                Delete file version
              </Button>
              <TextButton data-testid="cancel-button" onClick={handleClose} disabled={isLoading || deleteLoading}>
                Cancel
              </TextButton>
            </FlexBox>
          </>
        )}
      
      </FlexBox>
    </Modal>
  );
};

export default DeletePackagingManagerFileVersionModal;
