import React from "react";
import { Title as VdsTitle, TitleProps as VdsTileProps } from "@vds/typography";
import { StyledWrapper } from "./Title.module";

export interface TitleProps {
  children: React.ReactNode | string;
  pd?: string;
  maxWidth?: string;
  alignText?: "left" | "center" | "right";
  className?: string;
  testId?: string;
  breakFileName?: boolean;
}

const Title = ({
  children,
  pd,
  maxWidth,
  alignText,
  className,
  testId,
  breakFileName,
  ...rest
}: TitleProps & VdsTileProps) => {
  return (
    <StyledWrapper
      className={className}
      alignText={alignText}
      pd={pd}
      maxWidth={maxWidth}
      data-testid={testId}
      breakFileName={breakFileName}
    >
      <VdsTitle {...rest}>{children}</VdsTitle>
    </StyledWrapper>
  );
};

export default Title;
