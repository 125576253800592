import { useEffect, useState } from "react";
import axios, { AxiosProgressEvent } from "axios";
import { v4 as uuid } from "uuid";
import {
  useLazyGetAwsPolicyQuery,
  useLazyGetAwsVideoPolicyQuery,
} from "services/api/api.slice";
import { useDispatch, useSelector } from "./redux";
import { ParsedUserJWT } from "interfaces/Cognito";
import { selectCurrentUserDetails } from "services/auth/auth.slice";
import { setNotification } from "services";

const useS3Upload = () => {
  const dispatch = useDispatch(),
    userDetails: ParsedUserJWT | null | undefined = useSelector(
      selectCurrentUserDetails
    );

  const [callGetPolicy, { isFetching }] = useLazyGetAwsPolicyQuery(),
    [callGetVideoPolicy, { isFetching: videoFetching }] =
      useLazyGetAwsVideoPolicyQuery(),
    [loading, _setLoading] = useState<boolean>(false),
    onSuccess = async (
      awsPolicy: any,
      newFile: File | null,
      handleSuccess: Function,
      progressCallback?: Function,
      isVideo?: boolean
    ) => {
      if (newFile) {
        const fileUuid = uuid(),
          createdOn = new Date(newFile.lastModified),
          extension = newFile.name.split(".").pop()?.toLowerCase(),
          fileName = newFile.name,
          mimeType = newFile.type,
          modifiedOn = new Date(newFile.lastModified),
          size = newFile.size,
          bodyFormData = new FormData();

        // const key = file.type.includes('video')
        // ? `${this.state.awsVideoSource}/${formattedFileVersionId}/${file.name}`
        // : `${this.state.awsData.uploadDirectory}/RL${formattedFileVersionId}.${fileExtension}`;

        const uploadLocation = isVideo
          ? `assets_video/Source/${fileUuid.replaceAll("-", "")}/${
              newFile.name
            }`
          : `assets/Source/RL${fileUuid.replaceAll("-", "")}.${newFile.name
              .split(".")
              .pop()
              ?.toLowerCase()}`;

        bodyFormData.append("key", uploadLocation);
        bodyFormData.append("AWSAccessKeyId", awsPolicy.accessKey);
        bodyFormData.append("acl", "private");
        bodyFormData.append("success_action_status", "201");
        bodyFormData.append("policy", awsPolicy.policy);
        bodyFormData.append("signature", awsPolicy.signature);
        bodyFormData.append("Content-Type", newFile.type);
        bodyFormData.append(
          "Content-Disposition",
          `attachment; filename=${newFile.name}`
        );
        bodyFormData.append("file", newFile);
        await axios({
          method: "post",
          url: `https://${awsPolicy.bucket}.s3.amazonaws.com/`,
          data: bodyFormData,
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            progressCallback && progressCallback(newFile.name, progressEvent);
          },
        })
          .then(() => {
            const response = {
              approvalStatus: "Pending",
              clipId: null,
              createdOn,
              extension,
              fileName,
              id: fileUuid,
              mimeType,
              modifiedOn,
              previewUrl: null,
              sizeInBytes: size,
              uploadedBy: userDetails,
            };

            _setLoading(false);
            dispatch(
              setNotification({
                type: "success",
                message: "File has been uploaded. Submit still required.",
              })
            );
            handleSuccess(response);
          })
          .catch(handleError);
      }
    },
    uploadS3 = async (
      newFile: File | null,
      handleSuccess: Function,
      progressCallback?: Function
    ) => {
      _setLoading(true);
      if (newFile?.type.includes("video")) {
        await callGetVideoPolicy()
          .unwrap()
          .then((awsPolicy: any) =>
            onSuccess(awsPolicy, newFile, handleSuccess, progressCallback, true)
          )
          .catch(handleError);
      } else {
        await callGetPolicy()
          .unwrap()
          .then((awsPolicy: any) =>
            onSuccess(
              awsPolicy,
              newFile,
              handleSuccess,
              progressCallback,
              false
            )
          )
          .catch(handleError);
      }
    },
    handleError = (err: any) => {
      dispatch(
        setNotification({
          type: "error",
          message: "An error has occurred, please try again.",
        })
      );
      _setLoading(false);
    };

  return { uploadS3, loading: loading || isFetching || videoFetching };
};

export default useS3Upload;
