import { addBusinessDays } from 'date-fns';

export function formatDate(src: any, hasTime?: boolean): string {
  const dateType = typeof src.getMonth === "function";
  if (!dateType && src.trim() === "") {
    return "";
  }

  const dateObject = !dateType ? new Date(src) : src;
  const month = dateObject.getUTCMonth() + 1;
  const date = dateObject.getUTCDate();
  const year = dateObject.getUTCFullYear();
  const hour = dateObject.getUTCHours();
  const minute = dateObject.getUTCMinutes();
  const time = `${hour < 13 ? (hour < 1 ? 12 : hour) : hour - 12}:${
    minute < 10 ? `0${minute}` : minute
  }`;
  const meridiem = hour < 12 ? "AM" : "PM";
  if (hasTime) {
    return `${month}/${date}/${year} ${time} ${meridiem}`;
  } else {
    return `${month}/${date}/${year}`;
  }
}

export function formatTestDate(date: Date) {
  const day = (date.getDate() < 10 ? "0" : "") + date.getDate(),
    month = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1),
    year = date.getFullYear();

  return [month, day, year].join("/");
}

export function isExpired(src: string): boolean {
  if (!src || src.trim() === "") {
    return false;
  }

  const dateSrc = new Date(src);
  const dateNow = new Date();
  return dateSrc < dateNow;
}

export function expiresWithin90Days(assetExpirationDate: string): boolean {
  let expiresWithin90: boolean = false;
  if (!assetExpirationDate) {
    return expiresWithin90;
  }
  if (assetExpirationDate.trim() === "") {
    return expiresWithin90;
  }
  const expirationDate = new Date(assetExpirationDate);

  const today = new Date();
  const todayUTC = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate()
  );
  const todayPlus90 = todayUTC.getTime() + 90 * 1000 * 60 * 60 * 24;

  const diffTime = expirationDate.getTime() - todayPlus90;
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays <= 0) {
    expiresWithin90 = true;
  } else if (diffDays > 0) {
    expiresWithin90 = false;
  }

  return expiresWithin90;
}

export function cleanDate(d: string): string {
  return d.replace(/[/]/gi, "-");
}

export function setDateAsExpired(): string {
  const d = new Date();
  d.setUTCDate(d.getUTCDate() - 1);
  const formattedDate = cleanDate(formatDate(d));
  return formattedDate;
}

export function adjustDateByDays(days: number, date: Date, businessDaysOnly?: boolean): Date {
  // console.log('days: ', days);
  // console.log('date: ', date);
  let d = new Date(date);
  if (businessDaysOnly) {
    d = addBusinessDays(d, days);
  } else {
    d.setUTCDate(d.getUTCDate() + days);
  }
  
  // console.log('d: ', d);
  return d;
}

export function addDaysToCurrentDate(days: number): Date {
  let upperDateLimit = new Date();
  upperDateLimit.setDate(upperDateLimit.getDate() + days);
  return upperDateLimit;
}

export function getLocalDate(date: Date | string | null): Date {
  // https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
  const dateString = !!date
    ? typeof date === "string"
      ? date
      : date.toISOString()
    : "";

  return new Date(dateString.replace(/-/g, "/").replace(/T.+/, ""));
}
