import { useState } from "react";
import axios, { AxiosProgressEvent } from "axios";
import { v4 as uuid } from "uuid";
import {useLazyGetAwsWorkflowPolicyQuery,
} from "services/api/api.slice";
import { useDispatch, useSelector } from "./redux";
import { ParsedUserJWT } from "interfaces/Cognito";
import { selectCurrentUserDetails } from "services/auth/auth.slice";
import { setNotification } from "services";
import {FileCreateForm} from "../interfaces";

const useS3WorkflowUpload = (parentFileId?: string, instanceId?: string) => {
  const dispatch = useDispatch(),
    userDetails: ParsedUserJWT | null | undefined = useSelector(
      selectCurrentUserDetails
    );
  
  const [callGetWorkflowPolicy, { isFetching }] = useLazyGetAwsWorkflowPolicyQuery();
  const [loading, _setLoading] = useState<boolean>(false);
  const onSuccess = async (
      awsPolicy: any,
      newFile: File | null,
      handleSuccess: Function,
      progressCallback?: Function,
      blockId?: string,
    ) => {
      if (newFile) {
        const fileExtension = newFile.name.split(".").pop();
        const fileUuid = uuid(),
          createdOn = new Date(newFile.lastModified),
          extension = fileExtension ? fileExtension.toLowerCase() : "png",
          fileName = newFile.name,
          mimeType = newFile.type,
          modifiedOn = new Date(newFile.lastModified),
          size = newFile.size,
          bodyFormData = new FormData();
       
        const uploadLocation = `${awsPolicy.uploadDirectory}/${fileUuid.replaceAll("-", "")}.${newFile.name
          .split(".")
          .pop()
          ?.toLowerCase()}`;
        
        bodyFormData.append("key", uploadLocation);
        bodyFormData.append("AWSAccessKeyId", awsPolicy.accessKey);
        bodyFormData.append("acl", "private");
        bodyFormData.append("success_action_status", "201");
        bodyFormData.append("policy", awsPolicy.policy);
        bodyFormData.append("signature", awsPolicy.signature);
        bodyFormData.append("Content-Type", newFile.type);
        bodyFormData.append(
          "Content-Disposition",
          `attachment; filename=${newFile.name}`
        );
        bodyFormData.append("file", newFile);
        await axios({
          method: "post",
          url: `https://${awsPolicy.bucket}.s3.amazonaws.com/`,
          data: bodyFormData,
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            progressCallback && progressCallback(newFile.name, progressEvent);
          },
        })
        .then(() => {
          const response: FileCreateForm = {
            extension,
            name: fileName,
            fileId: fileUuid,
            mimeType,
            sizeInBytes: size,
            parentFileId: parentFileId,
            instanceId: instanceId
          };
          
          _setLoading(false);
          dispatch(
            setNotification({
              type: "success",
              message: "File has been uploaded. Submit still required.",
            })
          );
          handleSuccess(response, blockId);
        })
        .catch(handleError);
      }
    };
  const uploadS3 = async (
      newFile: File | null,
      handleSuccess: Function,
      progressCallback?: Function,
      blockId?: string
    ) => {
      _setLoading(true);
      await callGetWorkflowPolicy()
      .unwrap()
      .then((awsPolicy: any) =>
        onSuccess(
          awsPolicy,
          newFile,
          handleSuccess,
          progressCallback,
          blockId,
        )
      )
      .catch(handleError);
    },
    handleError = (err: any) => {
      dispatch(
        setNotification({
          type: "error",
          message: "An error has occurred, please try again.",
        })
      );
      _setLoading(false);
    };
  
  return { uploadS3, loading: loading || isFetching };
};

export default useS3WorkflowUpload;
