import styled from "styled-components";
import { BodyProps } from "./Body";

export const StyledWrapper = styled.div<BodyProps>`
  height: fit-content;
  padding: ${(props) => props.pd || 0};
  display: ${(props) => (props.inline ? "inline" : "block")};

  p {
    font-size: ${(props) => (props.size === "large" ? "1rem" : props.size === "medium" ? "0.875rem" : "0.75rem")};
    font-family: ${(props) =>
      props.size === "large" ? "Verizon-NHG-eDS" : "Verizon-NHG-eTX"};
    color: ${(props) => props.color || ""};
    letter-spacing: ${(props) => (props.size === "large" ? "0.5px" : "0")};
    line-height: 1.5;
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};

    word-wrap: break-word;
    overflow-wrap: ${(props) => (props.breakFileName ? "anywhere" : "break-word")};
    display: ${(props) => (props.inline ? "inline" : "block")};
  }

  &.ellipsis {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &.nowrap {
    p {
      white-space: nowrap;
    }
  }

  max-width: ${(props) => props.maxWidth || ""};
  width: ${(props) => props.width || ""};
`;
