import {
  DatePicker as MuiDatePicker,
  DateView,
  DateValidationError,
} from "@mui/x-date-pickers-pro";
import { StyledDatePicker } from "./DatePicker.module";
import {useEffect, useState} from "react";
import { Body, IconButton } from "components";
import { formatDate } from "utils";
import { ClickAwayListener } from "@mui/material";

export interface DatePickerProps {
  value: Date | null;
  name?: string;
  label?: string;
  className?: string;
  onChange: (value: Date, submit: boolean) => void;
  disabled?: boolean;
  views?: DateView[];
  pd?: string;
  minDate?: Date;
  maxDate?: Date;
  onClear?: any;
  allowClear?: boolean;
  optional?: boolean;
  testId?: string;
  shouldDisableDate?: ((date: Date) => boolean) | null;
  isError?: boolean;
}

const DatePicker = ({
  value,
  name,
  label,
  className,
  onChange,
  disabled,
  views = ["year", "month", "day"],
  pd,
  minDate,
  maxDate,
  onClear,
  allowClear = true,
  optional,
  testId = "date-picker-input",
  shouldDisableDate,
  isError,
}: DatePickerProps) => {
  const [date, _date] = useState<Date | null>();

  // boolean state (showStartError) to determine if show error (otherwise will show as soon as user starts typing) & error state (startError)
  const [showError, _showError] = useState(false),
    [error, _error] = useState<string | undefined>(undefined),
    errorMessage = () => {
      switch (error?.toString()) {
        case "maxDate":
          return maxDate
            ? `Please select a date prior to ${formatDate(maxDate)}`
            : null;
        case "minDate":
          return minDate ? "Please select a future date" : null;
        case "invalidDate":
          return "Your date is not valid";
        default:
          return null;
      }
    },
    [fireClickAway, _fireClickAway] = useState(false),
    handleBlur = () => {
      if (fireClickAway) {
        if (!!errorMessage()) {
          _showError(true);
        } else if (!!date) {
          _showError(false);
          onChange(date, true);
        } else {
          handleClear();
        }
        _fireClickAway(false);
      }
    };

  const setCustomError = () => {
    _showError(true);
  };
  
  useEffect(() => {
    if (isError) {
      setCustomError();
    }
  }, [isError]);

  const handleClear = () => {
    if (onClear) {
      onClear();
      _date(null);
    }
  };
  
  return (
    <StyledDatePicker pd={pd}>
      <Body pd="0 0 0.125rem">
        {label} {optional ? <span className="optional">Optional</span> : ""}
      </Body>
      <ClickAwayListener onClickAway={handleBlur}>
        <MuiDatePicker
          value={value}
          className={`date-picker ${className}`}
          onChange={(newDate) => {
            _date(newDate);
            !!newDate && onChange(newDate, false);
          }}
          onAccept={(newDate) => {
            !!newDate && onChange(newDate, true);
          }}
          disabled={disabled}
          onError={(newError: DateValidationError) =>
            _error(newError?.toString())
          }
          maxDate={maxDate ? maxDate : new Date(9999, 12, 31)}
          minDate={minDate ? minDate : new Date(1980, 1, 1)}
          shouldDisableDate={shouldDisableDate ? shouldDisableDate : () => false}
          shouldDisableMonth={() => false}
          shouldDisableYear={() => false}
          disableHighlightToday
          slotProps={{
            textField: {
              name: name,
              size: "small",
              error: showError && isError ? true : showError && !isError ? !!errorMessage() : false,
              helperText: showError ? errorMessage() : null,
              onFocus: () => _fireClickAway(true),
              onKeyDown: (e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  // @ts-ignore
                  e.target.blur();
                  handleBlur();
                }
              },
              inputProps: {
                "data-testid": testId,
              },
              InputProps: {
                endAdornment:
                  allowClear && value !== null ? (
                    <IconButton
                      testId={testId ? `${testId}-clear` : "clear-date"}
                      noHover
                      name="close"
                      onClick={handleClear}
                    />
                  ) : (
                    <></>
                  ),
              },
            },
            inputAdornment: {
              // children: (
              //   <IconButton
              //     name="calendar"
              //     size="medium"
              //     color="#000000"
              //     noHover
              //   />
              // ),
              position: "start",
            },
          }}
          views={views}
        />
      </ClickAwayListener>
    </StyledDatePicker>
  );
};

export default DatePicker;
