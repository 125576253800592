import { Component } from "react";
import { PackagingBriefPreviewProvider } from "contexts/packagingBriefPreviewContext";

const withPackagingManagerBriefPreviewContext = (WrappedComponent: any) => {
  return class extends Component {
    render() {
      return (
        <PackagingBriefPreviewProvider>
          <WrappedComponent {...this.props} />
        </PackagingBriefPreviewProvider>
      );
    }
  };
};

export default withPackagingManagerBriefPreviewContext;