import { useEffect, useState } from "react";

interface validationProps {
  values: any;
  validationSchema: any;
}

const useValidationSchema = ({ values, validationSchema }: validationProps) => {
  const [formValid, _setFormValid] = useState<boolean>(false);
  const checkValid = async () =>
    await validationSchema
      .isValid(values, {})
      .then((res: boolean) => _setFormValid(res));
  useEffect(() => {
    checkValid();
  }, [values]);
  return formValid;
};

export default useValidationSchema;
